.b-reviewSegment {
  // Width of quesiton status
  --quesitonStatusSize: 1.25rem;

  &__body {
    // Width of status indicator + cluster gap
    padding-inline-start: calc(
      var(--quesitonStatusSize) + theme('spacing.400')
    );
  }
}
