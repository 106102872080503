/* Spacing Utility Mixin
 * 
 * A mixin for creating consistent spacing utils
 * Used with composition classes such as flow and switcher to target their 
 * css control variables
 */

@mixin spacingUtility($variableName: '--varName', $inner: false) {
  @for $i from 1 through 9 {
    @if $inner {
      &-inner--#{$i}00 {
        & > * {
          #{$variableName}: theme('spacing.#{$i}00');
        }
      }
    } @else {
      &--#{$i}00 {
        #{$variableName}: theme('spacing.#{$i}00');
      }
    }
  }
}
