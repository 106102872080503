/**
 * Layer 02 - Elements Text
 */

// Headers
h1 {
  @include h1Style;
}

h2 {
  @include h2Style;
}

h3 {
  @include h3Style;
}

h4 {
  @include h4Style;
}

h5 {
  @include h5Style;
}

h6 {
  @include h6Style;
}

p {
  @include defaultBodyText;
  color: var(--body-text-color, theme('colors.newsecondary.500'));
}

li {
  @include defaultBodyText;
  color: var(--body-text-color, theme('colors.newsecondary.500'));
}

a {
  @include defaultBodyText;
  color: theme('colors.newprimary.pureblack');
}
