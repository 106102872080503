/*
  Colors:

  BG Blue:  #F4FAFF
  Offwhite: #F4FAFF

*/

/// GLOBAL VARIABLES
:root {
  --copy-color-default: theme('colors.secondary.800');
  --bg-dark: theme('colors.secondary.900');
  --bg-mid: theme('colors.secondary.800');
  --bg-light: theme('colors.secondary.300');
  --button-bg-default: theme('colors.primary.300');
}
