/** FLOW
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/stack/
 * A simple layout that adds spacing between elements via their parent
 * Values for margins can be found within _tokens.scss under $gorko-space-scale

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --flow-space (): Determines the amount of space
 * between items
 */

.c-flow {
  --flow-default-spacing: theme('spacing.400');
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * + * {
    margin-block-start: var(--flow-space, var(--flow-default-spacing));
  }

  &--page {
    & > * + * {
      margin-block-start: var(--page-flow-space, theme('spacing.900'));
    }
  }

  &--horizontal {
    flex-direction: row;
    margin-left: calc(var(--flow-space, var(--flow-default-spacing)) * -1);
    margin-right: calc(var(--flow-space, var(--flow-default-spacing)) * -1);

    // Needs to happen on all the children, not just siblings
    & > * {
      margin-left: var(--flow-space, var(--flow-default-spacing));
      margin-right: var(--flow-space, var(--flow-default-spacing));
    }
  }
}

.u-flow-space {
  @include spacingUtility('--flow-space');
  @include spacingUtility('--flow-space', true);
}

.u-page-flow-space {
  @include spacingUtility('--page-flow-space');
}

// @include media-query('md') {
//   .md\:u-flow-space {
//     @include spacingUtility('--flow-space');
//   }
// }

// @include media-query('md') {
//   .md\:u-page-flow-space {
//     @include spacingUtility('--page-flow-space');
//   }
// }
