/** IMPOSTER
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/imposter/
 * An absolutely postioned layer containing a modal

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --imposter-margin (0): Controls the space between the
 * content modal and the edge the outer wrapper

 * --imposter-poster (absolute): Gives an exception for
 * setting the outer wrapping layer to position fixed.
 */

.c-imposter {
  position: var(--imposter-position, absolute);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);

  &--contain {
    overflow: auto;
    max-inline-size: calc(100% - (var(--margin, 0) * 2));
    max-block-size: calc(100% - (var(--margin, 0) * 2));
  }

  &--fixed {
    --imposter-position: fixed;
  }
}
