/** STACK
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/grid/
 * A media component for cropping content similar to a bg img with a sizing of cover (with the added benefit of SEO alt tags)

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --n: Numerator, or width

 * --d: Denominator, or height
 */

@mixin frame {
  // Defaults to widescreen aspect ratio
  aspect-ratio: var(--n, 16) / var(--d, 9);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img,
  & > video {
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
  }
}

.c-frame {
  @include frame;

  /* #region - aspect ratio exceptions */
  // Some common aspect ratios pulled from wiki
  // https://en.wikipedia.org/wiki/Aspect_ratio_(image)
  // Fullscreen
  &--aspect-4-3 {
    --n: 4;
    --d: 3;
  }

  // Mobile
  &--aspect-6-13 {
    --n: 6;
    --d: 13;
  }

  // Square
  &--aspect-1-1 {
    --n: 1;
    --d: 1;
  }

  &--aspect-12-5 {
    --n: 12;
    --d: 5;
  }

  &--circle {
    --n: 1;
    --d: 1;
    border-radius: 50%;
  }
  /* #endregion - aspect ratio exceptions */
}

@mixin aspectNumber($dimension) {
  @for $i from 1 through 20 {
    &--#{$i} {
      --#{$dimension}: #{$i};
    }
  }
}

.u-frame-aspect {
  &-n {
    @include aspectNumber('n');
  }
  &-d {
    @include aspectNumber('d');
  }
}
