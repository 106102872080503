/** AUTO GRID
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://piccalil.li/tutorial/create-a-responsive-grid-layout-with-no-media-queries-using-css-grid/
 * A flexible layout that will create an auto-fill grid with
 * configurable grid item sizes

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --gutter (spacing token 400): This defines the space
 * between each item.

 * --auto-grid-gutter (--gutter): A composition specific --gutter override

 * --auto-grid-min-item-size (16rem): How large each item should be
 * ideally, as a minimum.

 * --auto-grid-placement (auto-fill): Set either auto-fit or auto-fill
 * to change how empty grid tracks are handled
 */

@mixin c-auto-grid-ordering {
  & .c-autoGrid__order1 {
    order: 1;
  }
  & .c-autoGrid__order2 {
    order: 2;
  }
}

// @mixin c-auto-grid-ordering-breakpoint($breakpoint: 'md') {
//   // Unset order below $breakpoint so things stack as marked up (e.g. MediaAndCopy)
//   @if $breakpoint == 'xs' or $breakpoint == 'sm' or $breakpoint == 'md' or $breakpoint == 'lg' or $breakpoint == 'xl' {
//     @include media-query($breakpoint) {
//       @include c-auto-grid-ordering;
//     }
//   } @else {
//     @media only screen and (min-width: $breakpoint) {
//       @include c-auto-grid-ordering;
//     }
//   }
// }

@mixin c-auto-grid() {
  --auto-grid-gutter: theme('gutter.default');

  display: grid;
  grid-template-columns: repeat(
    var(--auto-grid-placement, auto-fill),
    minmax(var(--auto-grid-min-item-size, 16rem), 1fr)
  );
  gap: var(--auto-grid-gutter, var(--gutter));
}

.c-autoGrid {
  $self: &;
  @include c-auto-grid;

  &--layout-50-50 {
    --auto-grid-placement: auto-fit;
    // --auto-grid-min-item-size: clamp(16rem, 50vw, 26rem);
    --auto-grid-min-item-size: clamp(16rem, 50vw, 25rem);
    // --auto-grid-gutter: #{get-space('800')};

    // &#{$self} {
    //   &--break-md {
    //     grid-template-columns: 1fr;
    //     @include c-auto-grid-ordering-breakpoint;

    //     @include media-query('md') {
    //       grid-template-columns: repeat(
    //           var(--auto-grid-placement, auto-fill),
    //           minmax(var(--auto-grid-min-item-size, 16rem), 1fr)
    //         );

    //       #{$self} {
    //         // Long term clamp may end up as a better target transition-property
    //         // But for now padding is easier to grasp and implement
    //         &__order {
    //           &1 {
    //             // Targets an inner child of a column
    //             #{$self}__column__tight {
    //               margin-inline-end: var(--auto-grid-tight-col-pad, get-space('900'));
    //             }
    //           }

    //           &2 {
    //             // Targets an inner child of a column
    //             #{$self}__column__tight {
    //               margin-inline-start: var(--auto-grid-tight-col-pad, get-space('900'));
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &--layout-30 {
    --auto-grid-placement: auto-fit;
    --auto-grid-min-item-size: clamp(16rem, 50vw, 20rem);
  }
}

// @include media-query('md') {
//   .md\: {
//     &c-autoGrid {
//       @include c-auto-grid;
//     }

//     &u-autoGird__tight_column-pad {
//       @include spacingUtility('--auto-grid-tight-col-pad');
//     }
//   }
// }

// @include media-query('lg') {
//   .lg\: {
//     &u-autoGird__tight_column-pad {
//       @include spacingUtility('--auto-grid-tight-col-pad');
//     }
//   }
// }
