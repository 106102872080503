.t-SidebarTemplate {
  $self: &;

  --sidebar-gutter: 0;
  --sidebar-content-min-width: 100%;

  & > div {
    display: flex;
  }

  .b-wayfinder {
    order: 2;
  }

  &__body {
    order: 1;
  }

  &__content {
    --sidebar-gutter: theme('spacing.800');
    --sidebar-width: 19.6875rem;
    --sidebar-content-min-width: 100%;

    @media only screen and (min-width: theme('screens.xxl')) {
      --sidebar-content-min-width: 50%;
    }
  }

  @media only screen and (min-width: theme('screens.lg')) {
    --sidebar-content-min-width: 75%;
    #{$self} {
      &__body {
        order: 2;
      }

      .b-wayfinder {
        order: 1;
      }
    }
  }
}
