.b-wayfinder {
  --sidebar-width: 15.6255rem;

  ::-webkit-scrollbar {
    width: 0.625rem;
    height: 8px;
    padding: 2px;
  }
  ::-webkit-scrollbar-corner {
    background-color: theme('colors.grayscale.700');
  }
  ::-webkit-scrollbar-thumb {
    background: radial-gradient(
          391.54% 96.34% at -104.55% 3.66%,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #2a2a37;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: theme('colors.black');
  }
}
