/** LAYER
 * A simple layout that fills it's parent container and
 * covers content below

 * CUSTOM PROPERTIES AND CONFIGURATION
 */

.c-withLayer {
  position: relative;
}

.c-layer {
  $self: &;

  position: var(--layer-position, absolute);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &:not(#{$self}--unselectable) {
    * {
      pointer-events: all;
    }
  }

  .c-layer {
    pointer-events: none;
  }

  &--shadowbox {
    --layer-position: fixed;
    pointer-events: all;
    // TODO: Replace with tailwind generater color
    background-color: rgba(0, 0, 0, 0.1);
  }
}
