/** COVER
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/cover/
 * A flex component for vertically centering items with the possibility of a header and footer

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --min-height (100vh): Controls the minimum height for
 * outer container

 * --cover-padding (get-space('400')): Controls the padding of
 * the outer element
 */

.c-cover {
  display: flex;
  flex-direction: column;
  min-block-size: var(--cover-min-height, 100vh);
  padding: var(--cover-padding, get-space('400'));

  & > * {
    margin-block: get-space('400');
  }

  & > :first-child:not(:only-child):not(.c-cover__center) {
    margin-block-start: 0;
  }

  & > :last-child:not(:only-child):not(.c-cover__center) {
    margin-block-end: 0;
  }

  & > .c-cover__center {
    margin-block: auto;
  }
}
