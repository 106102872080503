/** REEL
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/reel/
 * A horizontally scolling content frame

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --reel-item-width (auto): Controls the width of items
 * within the reel

 * --reel-item-spacing (): Controls the spacing between
 * items

 * --reel-height (): Controls the height of the reel
 */

@mixin c-reel {
  display: flex;
  block-size: var(--reel-height, auto);
  overflow-x: auto;
  overflow-y: hidden;

  & > * {
    flex: 0 0 var(--reel-item-width, auto);
  }

  & > img {
    block-size: 100%;
    flex-basis: auto;
    width: auto;
  }

  & > * + * {
    margin-inline-start: var(--reel-item-spacing, get-space('400'));
  }

  &--overflowing {
    padding-block-end: 1rem;
  }
}

.c-reel {
  @include c-reel;
}

// Sean: Composition break points are useful for swapping layouts with .js
// but when working in react I think I would still lean towards swapping
// the component out.
// With twig swapping out full components could still be done but
// still wouldn't be responsive without the assitance of .ts
@media only screen and (max-width: 879.98px) {
  .max-md\:c-reel {
    @include c-reel;
  }
}
