/** SIDEBAR
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/sidebar/
 * A fixed width container

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --sidebar-gutter (gutter default token: spacing 400): Sapce between sidebar and content pane

 * --sidebar-width (10rem): A target width for the sidebar element

 * --sidebar-content-min-width (50%): The minimum
 * size of the main content area
 */

@mixin sidebar($side) {
  $sidebar-placement: if($side == right, 'last', 'first');
  $content-placement: if($side == right, 'first', 'last');

  & > :#{$sidebar-placement}-child {
    flex-grow: 1;
    flex-basis: var(--sidebar-width, auto);
  }

  & > :#{$content-placement}-child {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: var(--sidebar-content-min-width, 50%);
  }
}

.c-withSidebar {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  gap: var(--sidebar-gutter, theme('gutter.default'));

  &--ltr {
    // The way this handled right now does have an exception as a 'requirement' however this can easily be defaulted within the twig template
    @include sidebar('left');
  }

  &--rtl {
    @include sidebar('right');
  }

  // A combination of the sidebar and wrapper where the main content is centered
  // Currently only works with regular wrapper size, wrapper defaults would
  // need to be adjusted to allow for wrapper expections to work
  &--wrapperCenter {
    --screen-space: calc(100vw - 15px);
    // Set Defaults
    --wrapper-width-max: 75rem;
    --wrapper-width: clamp(16rem, 95vw, var(--wrapper-width-max));

    --sidebar-gutter: 0;

    // Set Sidebar width
    // --content-withGap: calc(var(--wrapper-width) + calc(var(--sidebar-gutter) * 2));
    // --space-remaining: calc(var(--screen-space) - var(--content-withGap)); // Space remaining after accounting for content width and scrollbar
    --space-remaining: calc(var(--screen-space) - var(--wrapper-width));
    --wrapper-sidebar-width: calc(var(--space-remaining) / 2);

    justify-content: center;

    & > :first-child,
    & > :last-child {
      flex-grow: 1;
      flex-basis: var(--wrapper-sidebar-width);
    }

    & > :nth-child(2) {
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: calc(
        var(--wrapper-width) + var(--wrapper-sidebar-width)
      );

      --sidebar-width: var(--wrapper-sidebar-width);
      & > .c-withSidebar > .c-wrapper {
        margin-inline: 0; // Stop wrapper from auto-center
        max-inline-size: var(--wrapper-width);

        > * {
          max-width: 100%;
        }
      }
    }

    #{&} {
      &__noGrow {
        flex-grow: 0;
      }
    }
  }
}
