@mixin caretStyling {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.u-caret {
  --caret-color: theme('colors.primary.300');
  &-down {
    &::after {
      @include caretStyling();
      border-width: 8px 8px 0 8px;
      top: 100%;
      left: calc(50% - 6px);
      border-color: var(--caret-color) transparent transparent transparent;
    }
  }

  &-up {
    &::after {
      @include caretStyling();
      border-width: 0 8px 8px 8px;
      bottom: 100%;
      left: calc(50% - 6px);
      border-color: transparent transparent var(--caret-color) transparent;
    }
  }
}
