/**
 * Text Mixins
 */

@mixin defaultBodyText() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.paragraph-default');
  font-weight: theme('fontWeight.regular');
  line-height: 29.9px;
}

@mixin h1Style() { // Title XL
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-xl');
  font-weight: theme('fontWeight.regular');
  line-height: 79.2px;
}

@mixin h2Style() { // Title Large
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-lg');
  font-weight: theme('fontWeight.regular');
  line-height: 61.6px;
}

@mixin h3Style() { // Title Medium
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-md');
  font-weight: theme('fontWeight.regular');
  line-height: 61.6px;
}

@mixin h4Style() { // Title XSmall
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-xs');
  font-weight: theme('fontWeight.regular');
  line-height: 40.3px;
}

@mixin h5Style() { // Title XXSmall
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-xxs');
  font-weight: theme('fontWeight.regular');
  line-height: 24px;
}

@mixin h6Style() {
  font-family: theme('fontFamily.power-grotesk');
  font-size: theme('fontSize.title-xxxs');
  font-weight: theme('fontWeight.regular');
  line-height: 24px;
}

// DEPRECATED MIXINS
@mixin dep-defaultBodyText() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.4');
  font-weight: 300;
  line-height: 1.5;
}

@mixin dep-h1Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.7');
  font-weight: 700;
  line-height: 1.333333;
}

@mixin dep-h2Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.6');
  font-weight: 700;
  line-height: 1.25;
}

@mixin dep-h3Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.5');
  font-weight: 500;
  line-height: 1.1765;
}

@mixin dep-h4Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.4');
  font-weight: 500;
  line-height: 1;
}

@mixin dep-h5Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.4');
  font-weight: 700;
  line-height: 1.214;
}

@mixin dep-h6Style() {
  font-family: theme('fontFamily.open-sans');
  font-size: theme('fontSize.2');
  font-weight: 300;
  line-height: 1.2727;
}

/**
  * Custom Fonts
  */


@font-face {
  font-family: 'Power Grotesk'; // Standard License: https://power-type.com/font-license
  src: url('/fonts/PowerGrotesk-Regular.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Obvia'; // 10,000 pageview/mo License: https://www.fontspring.com/lic/s213vlrivf
  src: url('/fonts/obvia_light-webfont.woff2') format('woff2');
  font-style: normal;
}