/** BOX
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/box/
 * A layout component for intrinsic styles

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --box-padding (): The boxes padding between it's edge and inner content

 * --box-light (#fff): Light color that defaults to the background and is switched to the text color using the inverted exception.

 * --box-dark (#000): Dark color that defaults to the text color and is
 * switched the background color using the inverted exception

 * Either utility classes can be used to override the settings currently. Or if possible it would be useful to have utilies to easily target common color variables, which would allow for easier inversion of any color combinations.
 */
.c-box {
  padding: var(--box-padding, get-space('400'));
  outline: 0.125rem solid var(--box-outline-color, transparent);
  outline-offset: -0.125rem;

  color: var(--box-light, var(--color-core-text));
  background-color: var(--box-dark, transparent);

  // We may want to move this to an exception to enable the cascade
  // as opposed to it being the default
  // A lot of text goes into this comp and is pre-styled with a color default
  &--color-inherit {
    *:not(.c-box) {
      color: inherit;
    }
  }

  // Flips the font and background colors
  &--invert {
    color: var(--box-dark, var(--color-dark-text));
    background-color: var(--light, transparent);
  }
}

button.c-box {
  // &:focus-visible {
  //   outline: 0.125rem solid -webkit-focus-ring-color;
  // }

  &:focus-visible {
    outline: 2px solid get-color('grayscale-primary-500');
  }
}
