/** CLUSTER
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/cluster/
 * A grid layout component with no outer spacing

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --gutter (): This defines the space between each item.

 * --cluster-horizontal-alignment (flex-start): This controls the
 * position of items horizontally using flex

 * --cluster-vertical-alignment (center): This controls the
 * position of items vertically using flex
 */

.c-cluster {
  // Constants
  display: flex;
  flex-wrap: wrap;

  // Defaults
  gap: var(--cluster-gutter, theme('spacing.400'));
  justify-content: var(--cluster-horizontal-alignment, flex-start);
  align-items: var(--cluster-vertical-alignment, center);
}

.u-cluster-gutter {
  @include spacingUtility('--cluster-gutter');
}
