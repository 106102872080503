/** REPEL
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * A little layout that pushes items away from each other when
 * there is space in the viewport and stacks vertically
 * on smaller viewports

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --gutter (gutter default token: spacing 400): This defines the space between each item.

 * --repel-vertical-alignment (center): How items should align
 * vertically. Can be set to any acceptable flexbox alignment value.
 */

 .c-accordion {
  display: flex;
  flex-direction: column;
  gap: 8px;
 }

.c-accordion-button {
  display: flex;
  padding: 32px;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid var(--border-color, theme('colors.newprimary.purewhite'));
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.60);

  h5 {
    color: black;
  }

  img {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.c-dep-repel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: var(--repel-vertical-alignment, center);
  gap: var(--repel-gutter, theme('gutter.default'));

  // For use with repels that may only contain one item
  // This exception will keep that singule item defaulted to justify end
  // Keeping the design more consistent
  &--single-justify-end {
    *:only-child {
      margin-inline-start: auto;
    }
  }
}
