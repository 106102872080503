@media print {
  /* @TODO: figure out why TW `print:` option isn't working */
  .print\:u-hidden {
    display: none !important;
  }
}

@media print {
  * {
    color: black !important;
    max-width:100%;
  }

  nav,
  nav svg {
    color: black;
  }

  nav {
    border-bottom: 3px solid currentColor;
  }

  nav.u-justify-between {
    justify-content: end;
  }

  h3{
    page-break-after: avoid !important;
  }
  
  nav.u-justify-between .c-cluster,
  vercel-live-feedback,
  .marker-app,
  /* Back to Dashboard */
  a[href="/dashboard"],
  /* Print Page Button */
  button.u-group {
    display: none !important;
  }
  
  // HOLDING ON TO FOLLOWING THREE COLOR STYLING RULES IN CASE NEEDED OF FOR NON-CHROME BROWSERS IN THE FUTURE

  // .u-bg-gradient-blue-black,
  // .u-bg-gradient-blue-black > div,
  // .u-bg-gradient-blue-white,
  // .u-bg-gradient-blue-white > div,
  // .u-bg-gradient-blue-purple,
  // .u-bg-gradient-blue-purple > div {
  //   background: white !important;
  // }

  // .u-bg-primary-200,
  // .u-bg-primary-300 {
  //   background: #eee !important;
  // }

  // .u-border-primary-300 {
  //   border-color: currentColor;
  // }

   .c-dep-repel{
    page-break-after: avoid;
  }

  .o-line {
    background-color: #eee;
  }

  /* Card */
  .b-Card {
    border-bottom: 1px solid #eee;
  }

  p{
    page-break-after: avoid !important;
  }

  span{
    page-break-inside: avoid !important;
  }

  table, figure{
    page-break-inside: avoid !important;
  }

  .b-Card .b-AccordionItem__Drawer {
    max-height: none !important;
    page-break-inside: avoid;
  }

  @page {
    margin: .25in .25in;

    @top-right-corner {
      content: 'This page is intentionally left blank.';
    }
    @top-left-corner {
      content: 'This page is intentionally left blank.';
    }

    @footnote {
      border-top: 1pt solid black;
    }

    @bottom-left {
      margin: 10pt 0 30pt 0;
      border-top: 0.25pt solid #666;
      content: 'Our Cats';
      font-size: 9pt;
      color: #333;
    }

    @bottom-right {
      margin: 10pt 0 30pt 0;
      border-top: 0.25pt solid #666;
      content: counter(page);
      font-size: 9pt;
    }

    @top-right {
      content: string(doctitle);
      margin: 30pt 0 10pt 0;
      font-size: 9pt;
      color: #333;
    }
  }
}
