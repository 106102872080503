.lens {
  &-overline-text { // e.g. lens-overline-text
    font-family: theme('fontFamily.obvia');
    font-size: theme('fontSize.paragraph-default');
    color: var(--body-text-color, theme('colors.newprimary.pureblack'));
    font-weight: 300;
    line-height: 18.7px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }

  &-button-text {
    font-family: theme('fontFamily.obvia');
    font-size: theme('fontSize.paragraph-sm');
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &-large-paragraph-text {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.paragraph-lg');
    font-weight: 300;
    line-height: 29.9px;
  }

  &-small-paragraph-text {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.paragraph-sm');
    font-weight: 300;
    line-height: 22.6px;
  }
}

.o { // old, deprecated styles
  &-overline {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.2');
    font-weight: 300;
    line-height: 1.2727;
    text-transform: uppercase;
    letter-spacing: 0.25ch;
  }

  &-subtitle-1 {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.4');
    font-weight: 700;
    line-height: 1.4285;
  }

  &-subtitle-2 {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.3');
    font-weight: 500;
    line-height: 1.5;
  }

  // This is the default p, a and li deprecated tag styling
  &-body-1 {
    @include dep-defaultBodyText;
  }

  &-body-2 {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.1');
    font-weight: 300;
    line-height: 1.5;
  }

  &-caption {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.1');
    font-weight: 300;
    line-height: 1.5;
  }

  &-legal {
    font-family: theme('fontFamily.open-sans');
    font-size: theme('fontSize.1');
    font-weight: 300;
    line-height: 1.5;
  }

  &-h1 { //className="o-h1"
    @include dep-h1Style;
  }

  &-h2 {
    @include dep-h2Style;
  }

  &-h3 {
    @include dep-h3Style;
  }

  &-h4 {
    @include dep-h4Style;
  }

  &-h5 {
    @include dep-h5Style;
  }

  &-h6 {
    @include dep-h6Style;
  }
}
